<template>
  <div>
    <h4 class="mb-2">Informacion del pago</h4>
    <ul style="list-style: none; margin: 0;padding: 0;" v-if="detail && detail.user_bank_account">
      <li>
        <strong> Banco: </strong>
        {{ detail.user_bank_account.bank.name }}
      </li>
      <li>
        <strong> Titular: </strong>
        {{ detail.user_bank_account.owner ? detail.user.full_name : detail.user_bank_account.account_name }}
      </li>
      <li v-if="detail.user_bank_account.account_number">
        <strong> Numero de cuenta: </strong>
        {{ detail.user_bank_account.account_number }}
      </li>
      <li v-if="detail.payment_reference">
        <strong> Numero de referencia del pago: </strong>
        {{ detail.payment_reference }}
      </li>
    </ul>

     <ul v-if="detail && detail.personFullname">
        <li class="mb-1">
          <strong>Nombre completo: </strong>
            {{ detail.personFullname }}
        </li>
        <li class="mb-1">
          <strong>Tipo de Documento: </strong>
          {{ detail.personDocumentType }}
        </li>

        <li class="mb-1">
          <strong>Numero de documento: </strong>
          {{ detail.personDocumentNumber }}
        </li>
      </ul>

    <b-img :src="url" fluid style="width: 100%;margin-top: 2rem" />
  </div>
</template>

<script>
import { BModal, BImg } from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BImg,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  name: 'ShowDocumentModal',
}
</script>
