<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="detail">
      <b-card>
        <div class="pb-3">
          <b-button variant="primary" class="btn-icon mr-1" @click="() => $router.go(-1)">
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button variant="success" class="btn-icon mr-1">
            <feather-icon icon="RefreshCwIcon" size="16" />
          </b-button>
        </div>
        <b-row>
          <b-col cols="8" class="d-flex">
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <b-avatar class="mb-1 mr-2" size="6rem" :src="detail.user.avatar_url"
                  :initials="detail.user.initial_letters"></b-avatar>
                <div>
                  <h4 class="mb-0">
                    {{ detail.user.full_name }}
                  </h4>
                  <span class="card-text"><strong>Email:</strong> {{ detail.user.email }}</span>
                  <br />
                  <span class="card-text">
                    <strong>Telefono:</strong> {{ detail.user.phone }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Registrado desde:</strong>
                    {{ dateFormat(detail.user.created_at) }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Tipo de cuenta:</strong>
                    {{ detail.user.account_type }}
                  </span>
                  <br />
                </div>
              </div>
              <div class="text-right">
                <!--verified element-->
                <div v-if="detail.user.verified">
                  <b-badge variant="success" class="ml-1">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-25">Verificado</span>
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge variant="danger" class="ml-1">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                    <span class="align-middle ml-25">No verificado</span>
                  </b-badge>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="4">
            <!-- User Stats -->
            <div>
              <b-row>
                <b-col cols="12">
                  <h4>Informacion de la recarga</h4>
                  <ul style="list-style: none">
                    <li><strong> Estado:</strong> {{ detail.status_text }}</li>
                    <li>
                      <strong> Numero de operacion:</strong>
                      {{ detail.reference }}
                    </li>
                    <li>
                      <strong> Monto a acreditar:</strong>
                      $ {{ numberFormat(detail.recharge_amount) }}
                    </li>
                    <li>
                      <strong> Tipo de recarga:</strong>
                      {{ detail.payment_method.name }}
                    </li>
                    <li>
                      <strong> Recargo con: </strong>{{ numberFormat(detail.recharge_with_amount) }}
                    </li>
                    <li>
                      <strong> Deposito realizado a: </strong>{{ detail.platform_account.values.holder }}
                    </li>
                  </ul>
                </b-col>

                <b-col cols="12">
                  <h4>Informacion del pago</h4>
                  <ul style="list-style: none">
                    <li>
                      <strong> Banco: </strong>
                      {{ detail.user_bank_account.bank.name }}
                    </li>
                    <li>
                      <strong> Titular: </strong>
                      {{ detail.user_bank_account.account_name }}
                    </li>
                    <li>
                      <strong> Numero de cuenta: </strong>
                      {{ detail.user_bank_account.account_number }}
                    </li>
                    <li>
                      <strong> Numero de referencia del pago: </strong>
                      {{ detail.payment_reference }}
                    </li>
                    <li v-if="detail.voucher_of_payment">
                      <strong> Comprobante pago: </strong>
                      <span @click="showFile(detail.voucher_of_payment_url)"
                        class="text-primary cursor-pointer font-weight-bold">VER COMPROBANTE</span>
                    </li>
                  </ul>
                </b-col>

                <b-col cols="12" class="mb-2 mt-2" v-if="
                  detail.status === 'pending' || detail.status === 'in_review'
                ">
                  <b-button variant="danger" class="mr-2 btn-sm" @click="approve('rejected')"
                    v-if="permissions_requerid.includes('reject-recharge')">
                    Rechazar
                  </b-button>
                  <b-button variant="success" class="w-100" @click="approve('completed')"
                    v-if="permissions_requerid.includes('approve-recharge') && detail.pre_approved">
                    Aprobar
                  </b-button>
                  <b-button variant="success" class="w-100" @click="approve('pre-approved')"
                    v-if="permissions_requerid.includes('pre-approve-recharge') && detail.pre_approved === false">
                    Pre Aprobar
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <show-document-modal :show="showDocument" :url="urlDocument" :detail="detail" />
    </div>
  </b-overlay>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { inputAlert, confirmAlert } from '@/helpers/utils'
import ShowDocumentModal from './components/ShowDocumentModal'

moment.locale('es')
import {
  BOverlay,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BCardText,
  VBTooltip,
  BTab,
  BTabs,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'

import numberMixins from '@/mixins/numberMixins'
import { verifyPermission } from '@/router';

export default {
  components: {
    DatePicker,
    BBadge,
    vSelect,
    BOverlay,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BCardText,
    BTab,
    BTabs,
    ShowDocumentModal,
  },
  mixins: [numberMixins],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapState({
      loading: (state) => state.recharges.loading,
      detail: (state) => state.recharges.detail,
    }),
  },
  data() {
    return {
      showDocument: false,
      urlDocument: '',
      permissions_requerid: []
    }
  },
  watch: {},
  mounted() {
    // Verify
    let approve_recharge = verifyPermission({ namePerm: 'approve-recharge' })
    let reject_recharge = verifyPermission({ namePerm: 'reject-recharge' });
    let pre_approve_recharge = verifyPermission({ namePerm: 'pre-approve-recharge' });
    let permissions_requerid_verify = []
    if (approve_recharge) {
      permissions_requerid_verify.push("approve-recharge")
    }
    if (reject_recharge) {
      permissions_requerid_verify.push("reject-recharge")
    }

    if (pre_approve_recharge) {
      permissions_requerid_verify.push("pre-approve-recharge")
    }

    this.permissions_requerid = permissions_requerid_verify

  },
  methods: {
    async documentVerify(id, status, type) {
      const res = await this.$store.dispatch('recharges/documentVerify', {
        id,
        status: status,
        type,
      })

    },
    getNameFileType(type) {
      switch (type) {
        case 'front':
          return 'Frente'
        case 'backDocument':
          return 'Trasera'
        case 'selfie':
          return 'Selfie'
        default:
          return 'No definido'
      }
    },
    showFile(url) {
      this.urlDocument = url
      this.showDocument = true
    },
    getDetail() {
      this.$store.dispatch('recharges/getDetail', {
        id: this.$route.params.id,
      })
    },
    async approve(status) {
      let res = {
        isConfirmed: false,
        value: '',
      }
      if (status !== 'completed') {
        res = await inputAlert(
          'Rechazar recarga',
          'Ingrese el montivo del rechazo'
        )
      } else {
        res = await confirmAlert(
          'Aprobar recarga',
          '¿ Esta seguro de aprobar esta recarga ?'
        )
      }

      if (!res.isConfirmed) return

      this.$store.dispatch('recharges/approve', {
        id: this.$route.params.id,
        status: status,
        motive_rejected: res.value,
      })
    },
  },
  created() {
    this.getDetail()
  },
}
</script>
