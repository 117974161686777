import { numberFormat } from '@/helpers/numbers'
import dayjs from 'dayjs'
import es from 'dayjs/locale/es'

dayjs.locale('es')

export default {
  methods: {
    numberFormat(num) {
      return numberFormat(num)
    },
    dateFormat(date, format = 'DD/MM/YYYY') {
      // remove z of date
      // date = date.replace('Z', '')
      return dayjs(date).format(format)
    },
    dateFormatDetails(date) {
      return dayjs(date).format('DD MMM, YYYY - h:mm a')
    },
    getUserBlockedMotive(motive) {
      const MOTIVES = {
        'block_user': 'Usuario bloqueado',
        'unblock_user': 'Usuario desbloqueado',
      }

      return MOTIVES[motive] || 'Sin motivo'
    }
  },
}
